@import '../../../styles/partials/mixins';
@import '../../../styles/partials/variables';

.subNav {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  &__opened {
    max-height: calculateRem(500px);
    transition: all 0.5s ease-in-out;
  }

  @include respond-to('desktop') {
    position: absolute;
    top: 105%;
    min-width: calculateRem(260px);
    background: $color__background;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.36));
  }
}
