@import '../../../styles/partials/mixins';
@import '../../../styles/partials/variables';

.navItem {
  cursor: pointer;
  position: relative;

  .navlink {
    padding: calculateRem(17px) calculateRem(16px);
    font-family: $font__poppins;
    font-size: calculateRem(15px);
    line-height: calculateRem(53px);
    font-weight: $bold-weight;
    text-decoration: none;
    color: $color__green;
  }
  // class with subnav to highlight on mobile
  &__link {
    &__opened {
      background-color: $color__light-yellow;
      color: $color__gray-black-dark !important;
    }
  }

  &__topLevel {
    position: relative;
  }

  &__caret {
    transition: transform 0.25s ease-out;
    width: 0;
    height: 0;
    border-left: calculateRem(5px) solid transparent;
    border-right: calculateRem(5px) solid transparent;
    border-top: calculateRem(5px) solid $color__green;
    margin-left: calculateRem(5px);

    &__opened {
      transform: rotate(180deg);
      transition: transform 0.25s ease-in;
    }
  }
}

.dark,
.navItem.dark {
  .navlink,
  a {
    color: $color__background;

    &:hover {
      color: $color__wheat;

      .navItem__caret {
        border-top: calculateRem(5px) solid $color__wheat;
      }
    }
  }

  .navItem {
    &__link {
      &__opened {
        background-color: $color__gray-black-dark;
        color: $color__wheat !important;

        .navItem__caret {
          border-top: calculateRem(5px) solid $color__wheat;
        }
      }
    }
  }

  .navItem__caret {
    border-top: calculateRem(5px) solid $color__background;
  }
}
