@import '../../../styles/partials/mixins';
@import '../../../styles/partials/variables';

.nav {
  width: auto;
  overflow: visible;
  display: none;
  padding-right: 16px;
  padding-left: 16px;

  &__opened {
    display: block;
    margin-bottom: calculateRem(30px);
  }

  &.dark {
    > li a {
      color: $color__background;

      &:hover {
        color: $color__wheat;

        span:last-child {
          border-top: calculateRem(5px) solid $color__wheat;
        }
      }
    }

    ul li {
      background: #0f0f0f;
      border-bottom: 1px solid #e8e8e8;

      div {
        &:hover {
          background-color: $color__gray-black-dark;
          color: $color__wheat;
        }
      }
    }
  }

  > li {
    cursor: pointer;
    border-bottom: 1px solid #e8e8e8;

    a {
      padding: calculateRem(16px) calculateRem(20px);
      font-family: $font__poppins;
      font-weight: $bold-weight;
      font-size: calculateRem(19px);
      line-height: calculateRem(26px);
      color: $color__green;
      display: flex;
      align-items: center;

      &:hover {
        color: $color__gray-black-dark;

        span {
          &:last-child {
            border-top: calculateRem(5px) solid $color__gray-black-dark;
          }
        }
      }
    }

    ul {
      li {
        border-bottom: 1px solid #e8e8e8;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }

        div {
          padding: calculateRem(18px) calculateRem(20px);
          font-size: 16px;
          font-family: $font__main;
          font-weight: 600;
          letter-spacing: 0.069em;
          color: $color__gray-dark;
          line-height: calculateRem(24px) !important;

          &:hover {
            background-color: $color__light-yellow;
            color: $color__gray-black-dark;
          }
        }
      }
    }
  }

  @include respond-to('desktop') {
    display: flex;
    position: static;
    width: auto;
    height: 100%;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    background: none;
    margin-left: auto;

    > li {
      border-bottom: 0;

      a {
        height: 100%;
        padding: 0 calculateRem(17px);
        font-size: calculateRem(16px);
        line-height: calculateRem(26px);
      }
    }
  }
}

.loader {
  position: fixed;
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 9;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px !important;
  height: 50px !important;
}
.spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

