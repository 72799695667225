@import '../../styles/partials/variables';

.main {
    justify-content: space-between;
    align-items: center;
    margin-top: calculateRem(160px);
}
.errorText {
    width: calculateRem(500px);
}
.title {
    @include convertToRem(35px);
    line-height: calculateRem(41px);
    color: $color__green;
    font-weight: $xbold-weight;
    margin-bottom: calculateRem(30px);
    font-family: $font__secondary;
}
.button {
    width: calculateRem(150px);
    height: calculateRem(43px);
    border-radius: 50px;
    background-color: $color__green;
    padding: calculateRem(12px) calculateRem(24px);
    color: $color__light-wheat;
    text-decoration: none;
    @include convertToRem(16px);
    line-height: calculateRem(21px);
    font-family: $font__secondary;
    font-weight: $bold-weight;
    letter-spacing: calculateRem(0.83px);
}

.article__cards {
    padding: 20px 0 90px 0;
    grid-row-gap: calculateRem(16px);
  }
  
  .article__cards_title {
    display: flex;
    align-items: center;
    @include grid-col(2, $grid-columns: 2);
  
    @include respond-to('mobile') {
      @include grid-col(4, $grid-columns: 4);
    }
  
    @include respond-to('tablet') {
      @include grid-col(9, $grid-columns: 9);
    }
  
    @include respond-to('desktop') {
      @include grid-col(9, $grid-columns: 9);
    }
  
    h2 {
      font-family: 'Poppins', sans-serif;
      font-weight: $bold-weight;
      color: $color__green;
      line-height: 1.44;
      margin-right: calculateRem(11px);
      flex-shrink: 0;
      @include convertToRem(18px);
    }
  
    a {
      order: 3;
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: $bold-weight;
      line-height: 1.25;
      color: $color__green;
      text-transform: uppercase;
      margin-left: calculateRem(11px);
      text-decoration: none;
      flex-shrink: 0;
      @include convertToRem(12px);
      @include letter-spacing(69);
    }
  
    &::after {
      content: '';
      width: 100%;
      flex: 1;
      height: 3px;
      display: block;
      background: $color__green;
    }
  }
