@import '../../styles/partials/variables';

.article__card {
  width: 100%;
}

.columns-3 {
  @include grid-col(2, $grid-columns: 2, $max_width: true);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4, $max_width: true);
  }

  @include respond-to('tablet') {
    @include grid-col(3, $grid-columns: 9, $gutter: $gap_tablet, $max_width: true);
    margin-bottom: 0;
  }

  @include respond-to('desktop') {
    @include grid-col(3, $grid-columns: 9, $gutter: $gap_desktop, $max_width: true);
    margin-bottom: 0;
  }

  h3 {
    @include respond-to('tablet') {
      @include convertToRem(18px);
      line-height: 26px;
    }
  }

  h3 {
    @include respond-to('tablet') {
      @include convertToRem(18px);
      line-height: 26px;
    }
  }
}

.columns-4 {
  @include grid-col(2, $grid-columns: 2, $max_width: true);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4, $max_width: true);
  }

  @include respond-to('tablet') {
    @include grid-col(2.25, $grid-columns: 9, $gutter: $gap_tablet, $max_width: true);
    margin-bottom: 0;
  }

  @include respond-to('desktop') {
    @include grid-col(2.25, $grid-columns: 9, $gutter: $gap_desktop, $max_width: true);
    margin-bottom: 0;
  }
}

.feature-medium-5 {
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(2, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(9, $grid-columns: 9);
  }
}

.condensed-7 {
  @include grid-col(2, $grid-columns: 2);
  padding-top: calculateRem(24px);
  padding-bottom: calculateRem(24px);
  border-bottom: 1px solid $color__rule-lines;

  @media (min-width: 280px) and (max-width: 614px) {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include respond-to('mobile2') {
    @include grid-col(1.92, $grid-columns: 4);
  }

  @media (min-width: 615px) and (max-width: 912px) {
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:nth-last-child(2) {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:nth-child(odd) {
      margin-right: calculateRem(11px);
    }

    &:nth-child(even) {
      margin-left: calculateRem(11px);
    }
  }

  @include respond-to('tablet') {
    @include grid-col(9, $grid-columns: 9);

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include respond-to('desktop') {
    @include grid-col(2, $grid-columns: 4, $gutter: $gap_desktop);
    margin-bottom: 0;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:nth-last-child(2) {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.small-row-5 {
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(2.25, $grid-columns: 9, $gutter: $gap_tablet);
    margin-bottom: 0;
  }

  @include respond-to('desktop') {
    @include grid-col(2.25, $grid-columns: 9, $gutter: $gap_desktop);
    margin-bottom: 0;
  }
}

.article__card_small-row {
  display: flex;
  flex-direction: column;

  &.columns-6,
  &.columns-7,
  &.columns-8,
  &.columns-9 {
    @include grid-col(2, $grid-columns: 2);

    @include respond-to('mobile') {
      @include grid-col(4, $grid-columns: 4);
    }

    @include respond-to('tablet') {
      @include grid-col(2.25, $grid-columns: 9, $gutter: $gap_tablet);
      margin-bottom: 0;

      &:last-child {
        margin-right: 40px;
      }
    }

    @include respond-to('desktop') {
      @include grid-col(2.25, $grid-columns: 9, $gutter: $gap_desktop, $max_width: true);
      margin-bottom: 0;

      &:last-child {
        margin-right: 40px;
      }
    }
  }
}

.article__card_inline {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  padding-bottom: calculateRem(24px);
  border-bottom: 1px solid $color__rule-lines;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.article__card_list {
  display: flex;
  flex-direction: row;
  margin: calculateRem(24px) 0;
  border-bottom: 1px solid $color__rule-lines;
  @include respond-to('tablet2') {
    padding: 0px 0 1.5rem;
  }
}

.article__card_feature-medium {
  background: $color__light-wheat;
  box-shadow: $site_box_shadow;
  width: calc(100% - #{$site_box_shadow_size});
  margin-bottom: $site_box_shadow_size;

  &.white {
    background: $color__background;
  }

  > div {
    &:first-child {
      @include respond-to('tablet') {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    font-weight: $bold-weight;
    @include convertToRem(18px);
    line-height: 26px;

    @include respond-to('mobile2') {
      font-weight: $xbold-weight;
      @include convertToRem(25px);
      line-height: 31px;
    }
  }
}

.article__card_feature-full {
  background: $color__light-wheat;
  box-shadow: $site_box_shadow;
  width: calc(100% - #{$site_box_shadow_size});
  margin-bottom: $site_box_shadow_size;

  @include respond-to('tablet') {
    @include grid($align-items: center);
  }

  &.white {
    background: $color__background;
  }
}

.article__card_feature-small {
  background: $color__light-wheat;
  box-shadow: $site_box_shadow;
  width: calc(100% - #{$site_box_shadow_size});
  margin-bottom: $site_box_shadow_size;
  padding: calculateRem(28px) calculateRem(12px) calculateRem(28px) calculateRem(16px);
  @include grid($flex-direction: row, $align-items: center);

  @include respond-to('mobile2') {
    padding: 0;
  }

  &.white {
    background: $color__background;
  }
}

.article__card_in-focus {
  padding: calculateRem(28px) calculateRem(12px) calculateRem(28px) calculateRem(16px);
  @include grid($flex-direction: row, $align-items: center);

  @include respond-to('mobile2') {
    padding: 0;
  }

  @include respond-to('tablet') {
    margin-bottom: calculateRem(30px);
  }

  @include respond-to('desktop') {
    margin-bottom: calculateRem(40px);
  }
  @include respond-to('mobile3') {
    padding: calculateRem(28px) calculateRem(0px) calculateRem(0px) calculateRem(0px);
  }
}

.article__card_content_feature-medium {
  padding: calculateRem(28px) calculateRem(16px) calculateRem(28px);

  @include respond-to('mobile2') {
    padding: calculateRem(28px);
  }
}

.article__card_content_feature-full {
  padding: calculateRem(28px) calculateRem(16px) calculateRem(28px);

  @include respond-to('mobile2') {
    padding: calculateRem(28px);
  }
}

.article__card_content_feature-small {
  @include grid-col(1.4, $grid-columns: 2, $order: 1);
  padding-right: calculateRem(24px);

  @include respond-to('mobile2') {
    @include grid-col(2, $grid-columns: 4, $order: 2);
    padding-right: calculateRem(28px);
    padding-left: calculateRem(28px);
  }

  @include respond-to('tablet') {
    @include grid-col(4.5, $grid-columns: 9);
  }
}

.article__card_content_in-focus {
  background: $color__light-yellow;
  margin-left: 9px;
  padding: calculateRem(61px) calculateRem(35px) calculateRem(72px) calculateRem(43px);
  @include grid-col(3.6, $grid-columns: 9, $order: 2);
  @include respond-to('mobile3') {
    flex: 0 0 60.5%;
    margin-left: 0px;
    padding: calculateRem(20px) calculateRem(20px) calculateRem(20px) calculateRem(25px);
  }
}

.inline_image_wrapper {
  flex: 1;
  margin-left: calculateRem(26px);

  @include respond-to('mobile2') {
    margin-left: calculateRem(35px);
  }
}

.list_image_wrapper {
  flex: 1;
  margin-right: calculateRem(26px);

  @include respond-to('mobile') {
    margin-right: calculateRem(35px);
  }
}

.inline_content {
  @include grid-col(1.2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(2.5, $grid-columns: 4);
  }
}

.article__card_image {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;

  @include respond-to('tablet') {
    margin-bottom: calculateRem(18px);
  }

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.article__card_image_rotate {
  position: relative;
  padding-bottom: 143.83%;
  overflow: hidden;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.article__card_image_focused {
  position: relative;
  padding-bottom: 74.09%;
  overflow: hidden;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.article__card_image_full {
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile2') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(6.3, $grid-columns: 9);
    margin-bottom: calculateRem(-18px);
  }
}

.article__card_image_small {
  @include grid-col(0.6, $grid-columns: 2, $order: 2);

  @include respond-to('mobile2') {
    @include grid-col(2, $grid-columns: 4, $order: 1);
  }
}

.article__card_image_focus {
  box-shadow: $site_box_shadow;
  width: calc(100% - #{$site_box_shadow_size});
  @include grid-col(5.4, $grid-columns: 9, $order: 1);
  @include respond-to('mobile3') {
    flex: 1 1;
    margin-right: calculateRem(35px);
  }
}

.article__card_tags {
  display: flex;
  flex-flow: row wrap;

  li {
    font-family: $font__main;
    font-weight: $semibold-weight;
    line-height: 1.25;
    color: $color__gray-dark;
    text-transform: uppercase;
    margin-right: 4px;
    margin-bottom: 5px;
    @include convertToRem(12px);
    @include letter-spacing(69);
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $color__green;
    }
  }
}

.article__card_title {
  font-family: $font__poppins;
  font-weight: $bold-weight;
  color: $color__green;
  line-height: 21px;
  margin-bottom: calculateRem(12px);
  @include convertToRem(15px);

  &_full {
    font-weight: $bold-weight;
    @include convertToRem(18px);
    line-height: 26px;

    @include respond-to('mobile2') {
      font-weight: $xbold-weight;
      @include convertToRem(25px);
      line-height: 31px;
    }
  }

  &_list {
    margin-bottom: 2px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $color__gray-black-dark;
    }
  }
}

.article__card_meta {
  display: flex;
}

.article__card_date {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: $bold-weight;
  line-height: 1.25;
  color: $color__gray-dark;
  @include convertToRem(12px);
  @include letter-spacing(69);
}

.article__card_reading {
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.25;
  color: $color__gray-dark;
  margin-left: calculateRem(12px);
  padding-left: calculateRem(12px);
  border-left: 1px solid $color__gray-dark;
  @include convertToRem(12px);
  @include letter-spacing(69);
}

.article__card_excerpt {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  color: $color__gray-dark;
  @include convertToRem(14px);
}

.article__card_list_excerpt {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  color: $color__gray-dark;
  margin-bottom: calculateRem(16px);
  @include convertToRem(14px);
}

.article__card.light {
  .article__card_title {
    color: $color__wheat;

    a:hover {
      color: $color__rule-lines;
    }
  }

  .article__card_date {
    color: $color__rule-lines;
  }

  .article__card_tags a {
    color: $color__rule-lines;

    &:hover {
      color: $color__wheat;
    }
  }
  .article__card_excerpt {
    color: $color__rule-lines;
  }
}
