@import '../../styles/partials/variables';

.container {
  @include container();
  @include grid($flex-direction: column);

  @include respond-to('tablet') {
    @include grid($flex-direction: row);
  }
}

.condensed {
  max-width: $content_max;
  margin: calculateRem(70px) auto;
}

.nomargin {
  margin: 0;
  & > div {
    margin: 0 auto;
  }
}
