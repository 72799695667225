@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.headerContainer {
  position: relative;
  border-bottom: $color__green calculateRem(4px) solid;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: calculateRem(86px);

  svg {
    fill: $color__green;

    @include respond-to('desktop') {
      width: 18px;
      height: 18px;
    }

    &:hover {
      fill: $color__gray-black-dark;
    }

    line {
      stroke: $color__green !important;
    }
  }

  &__menuContainer {
    display: none;
    position: absolute;
    top: calculateRem(90px);
    left: 0;
    right: 0;
    height: calc(100vh - 90px);
    background: $color__background;
    z-index: 10;

    &__opened {
      display: flex;
      flex-direction: column;
    }

    &__bottom {
      margin-top: auto;
      padding-bottom: 24px;
      padding-right: 36px;
      padding-left: 36px;
      border-bottom: 11px solid $color__wheat;
      background: $color__background;
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      line-height: 23px;
      color: $color__green;

      a {
        color: $color__green;
        text-decoration: none;

        strong {
          font-weight: 900;
        }
      }
    }
  }

  &__hamburger {
    @include reset-button;
    position: absolute;
    left: calculateRem(25px);
    width: 28px;
    height: 22px;
  }

  &__search {
    padding: 0 calculateRem(15px);
    position: absolute;
    right: calculateRem(10px);
    cursor: pointer;
  }

  &__login__btn {
    @include reset-button;
    font-family: $font__poppins;
    font-size: calculateRem(15px);
    line-height: calculateRem(23px);
    font-weight: $bold-weight;
    background-color: $color__wheat;
    color: $color__gray-black-dark;
    border-radius: 23px;
    width: calculateRem(99px);
    height: calculateRem(46px);
    display: none;
    margin-bottom: calculateRem(10px);

    &:hover {
      background-color: $color__gray-black-dark;
    }

    &__opened {
      display: flex;
    }
  }

  @include respond-to('tablet') {
  }

  @include respond-to('desktop') {
    max-width: calculateRem(1238px);
    margin: 0 auto;

    &__hamburger {
      display: none;
    }

    &__search {
      position: static;
      padding: 0 calculateRem(40px) 0 calculateRem(34px);
    }

    &__login__btn {
      display: flex;
      background-color: $color__green;
      color: $color__background;
      margin-left: calculateRem(17px);
      margin-bottom: 0;
    }

    &__menuContainer {
      position: static;
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100%;

      &__bottom {
        margin-top: 0;
        padding: 0;
        border-bottom: 0;
      }

      p {
        display: none;
      }
    }
  }
}

.headerContainer.dark {
  $background: #0f0f0f;
  background-color: $background;
  border-bottom-color: $background;

  .header  {
    svg {
      fill: $color__background;

      &:hover {
        fill: $color__wheat;
      }
    }

    &__menuContainer {
      background-color: $background;

      &__bottom {
        background-color: $background;
      }
    }

    &__login__btn {
      background-color: $color__background;
      color: $color__gray-dark;

      &:hover {
        background-color: $color__wheat;
      }
    }
  }
}
