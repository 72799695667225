body {
  background: $color__background;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

main {
  padding: 0 $padding_small;

  @include respond-to('mobile') {
    padding: 0 $padding_mobile;
  }

  @include respond-to('tablet') {
    padding: 0 $padding_tablet;
  }

  @include respond-to('desktop') {
    padding: 0 $padding_desktop;
  }
}

hr {
  border-color: $color__rule-lines;
  border-bottom: 0;
  border-style: solid;
}

// move to helpers

.show-mobile {
  display: initial;
}

.show-desktop {
  display: none;
}

@media screen and (min-width: 1024px) {
  .show-mobile {
    display: none;
  }

  .show-desktop {
    display: initial;
  }
}
