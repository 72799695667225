@import '../../styles/partials/variables';

.form__wrapper {
  background: $color__light-wheat;
  padding: 0 calculateRem(12px);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.open {
  max-height: 200px;
}

.form {
  max-width: $max_width;
  padding: calculateRem(18px);
  margin: auto;
  display: flex;
  align-items: center;

  svg {
    fill: $color__green;

    line {
      stroke: $color__green !important;
    }
  }
}

.search__input {
  display: block;
  max-width: 56%;
  margin: auto;
  text-align: center;
  border: 0;
  background: transparent;
  color: $color__gray-dark;
  font-family: paralucent, sans-serif;
  font-weight: 700;
  outline: none;

  @include convertToRem(44px);

  &::placeholder {
    color: rgba($color__gray-dark, 0.41);
  }
}

.search {
  margin-right: auto;
}

.close {
  width: calculateRem(32px);
  height: calculateRem(32px);
  margin-left: auto;
}
