@import '../../styles//partials/mixins';
@import '../../styles/partials/variables';

.logo {
  a {
    text-decoration: none;
  }

  p {
    font-family: 'Poppins', sans-serif;
    text-align: center;

    &:first-of-type {
      @include convertToRem(23px);
      font-weight: $bold-weight;
      color: $color__logo-green;
    }
    &:nth-of-type(2) {
      @include convertToRem(20px);
      color: $color__logo-grey;
    }
  }

  @include respond-to('tablet') {
    padding-left: calculateRem(30px);
  }

  @include respond-to('desktop') {
    padding-left: calculateRem(40px);
  }

  &.dark {
    p:first-of-type,
    p:nth-of-type(2) {
      color: $color__background;
    }
  }
}
